import {
  CommonModule,
  Location,
  NgOptimizedImage,
} from '@angular/common';

import {
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  NgZone,
  signal
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import {
  NgxTolgeeModule,
  TranslateService
} from '@tolgee/ngx';

import {
  first,
  interval
} from 'rxjs';
import {environment} from '../../environments/environment';
import {ProfileCardComponent} from '../account/profile-card/profile-card.component';
import {SignInComponent} from '../account/sign-in/sign-in.component';
import {ClaimGiftComponent} from '../claim-gift/claim-gift.component';
import {emulateClick} from '../core/a11y'
import {DrawerService} from '../drawer/drawer.service';
import {NotificationService} from '../notification/notification.service';
import {SearchComponent} from '../search/search.component';
import {AutoCloseDirective} from '../shared/directives/auto-close.directive';
import {HoverGradientGreenDirective} from '../shared/directives/hover-gradient-green.directive';
import {HoverGradientDirective} from '../shared/directives/hover-gradient.directive';
import {HoverDirective} from '../shared/directives/hover.directive';
import {ProgressClickDirective} from '../shared/directives/progress-click.directive';
import {Center} from '../shared/model/center.model';
import {ProductGroup} from '../shared/model/product-group.model';
import {Product} from '../shared/model/product.model';
import {Universe} from '../shared/model/universe.model';
import {ByPassPipe} from '../shared/pipes/by-pass.pipe';
import {AuthService} from '../shared/services/auth.service';
import {CartService} from '../shared/services/cart.service';
import {CenterService} from '../shared/services/center.service';
import {GlobalService} from '../shared/services/globals.service';
import {HeaderService} from '../shared/services/header.service';
import {NavigationService} from '../shared/services/navigation.service';
import {ProductService} from '../shared/services/product.service';
import {SelectionService} from '../shared/services/selection.service';
import {SiteService} from '../shared/services/site.service';
import {UniverseService} from '../shared/services/universe.service';
import {VideoComponent} from '../video/video.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NgxTolgeeModule,
    NgOptimizedImage,
    HoverGradientDirective,
    AutoCloseDirective,
    VideoComponent,
    SignInComponent,
    ProfileCardComponent,
    ProgressClickDirective,
    HoverDirective,
    HoverGradientGreenDirective,
    ByPassPipe
  ],
  templateUrl: './header.component.html'
})

/**
 * Display the header
 */
export class HeaderComponent {
  zone = inject(NgZone);
  destroyRef = inject(DestroyRef);
  location = inject(Location);
  cdRef = inject(ChangeDetectorRef);
  authService = inject(AuthService);
  drawerService = inject(DrawerService);
  notificationService = inject(NotificationService);
  headerService = inject(HeaderService);
  centerService = inject(CenterService);
  universeService = inject(UniverseService);
  selectionService = inject(SelectionService);
  productService = inject(ProductService);
  globalService = inject(GlobalService);
  siteService = inject(SiteService);
  navigationService = inject(NavigationService);
  route = inject(ActivatedRoute);
  cartService = inject(CartService);
  translateService = inject(TranslateService);
  giftMode = signal(false);
  readonly emulateClick = emulateClick;
  readonly SignInComponent = SignInComponent;
  readonly SearchComponent = SearchComponent;
  showClaimGift = environment.showClaimGift;
  animatedImageIndex: [number, number] = [-1, 0]; // Index of the previously and currently animated images
  images = computed(() =>
    this.siteService.center()?.images.filter(i => i.header) ??
    this.globalService.landing().headerImages);
  textColorClasses = computed(() => {
    const search = this.headerService.search();
    let res = this.headerService.type() === 'minimal' ?
      'text-100ciels-300 hover:text-100ciels-400' :
      'text-100ciels-300 hover:text-100ciels-400 md:text-white md:hover:text-white';
    if (!search)
      res += ' lg:gap-3 lg:flex-row'
    return res;
  });

  svgColorClasses = computed(() => {
    return this.headerService.type() === 'minimal' ?
      'stroke-100ciels-300 group-hover:stroke-100ciels-400' :
      'stroke-100ciels-300 group-hover:stroke-100ciels-400 md:stroke-white md:group-hover:stroke-white';
  });

  constructor() {
    this.zone.onStable.pipe(first()).subscribe(() => {
      interval(5000).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.animatedImageIndex =
          [this.animatedImageIndex[1], (this.animatedImageIndex[1] + 1) % this.images().length];
        this.cdRef.detectChanges();
      });
      this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(map => {
        this.giftMode.set(map.get('gift') === 'true');
      });
    });
  }

  /**
   * Select current center
   * @param center The selected center
   */
  selectCenter(center: Center): void {
    this.selectionService.center.set(center);
  }

  /**
   * Select current universe
   * @param universe The selected universe
   */
  selectUniverse(universe: Universe | null): void {
    this.selectionService.universe.set(universe);
  }

  /**
   * Select current product
   * @param product The selected product
   */
  selectProduct(product: Product | ProductGroup | null): void {
    this.selectionService.product.set(product);
  }

  /**
   * Open old website or center website or selected center universe or a selected product
   */
  quickPurchase = (): void => {
    const center = this.selectionService.center();
    if (!center) {
      return;
    }
    const selectedProduct = this.selectionService.product();
    if (selectedProduct?.slug) {
      this.navigationService.goTo(center, `/product/${selectedProduct.slug}`);
      return;
    }
    const selectedUniverse = this.selectionService.universe();
    if (selectedUniverse) {
      this.navigationService.goTo(center, `/offers`, {universe: selectedUniverse.name});
      return;
    }

    this.navigationService.goTo(center, '/offers');
  };

  openClaimGiftComponent(): void {
    if (!this.authService.user()?.authCompleted) {
      this.drawerService.open(
        {component: SignInComponent, inputs: {inDrawer: true}, style: 'transparent'},
        {component: ClaimGiftComponent, inputs: undefined, style: 'transparent'});
      return;
    }
    this.drawerService.open({component: ClaimGiftComponent, inputs: undefined, style: 'transparent'})
  }
}
