import {
  isPlatformBrowser
} from '@angular/common';
import {
  Component,
  inject,
  NgZone,
  PLATFORM_ID
} from '@angular/core';
import {
  Router,
  RouterOutlet,
} from '@angular/router';

import {
  App,
  URLOpenListenerEvent
} from '@capacitor/app';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NotificationService } from './notification/notification.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: './app.component.html',
})
/**
 * Root component of the application
 */
export class AppComponent {
  private platformId = inject(PLATFORM_ID);
  private router = inject(Router);
  private zone = inject(NgZone);
  private notification = inject(NotificationService);

  constructor() {
    this.initializeApp();
  }

  initializeApp(): void {
    if (isPlatformBrowser(this.platformId)) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          const slug = event.url.split(".app").pop();
          if (slug) {
            this.router.navigateByUrl(slug)
              .catch(err => this.notification.open({ type: 'error', title: 'error', message: (err as Error).message }))
          }
        });
      });
    }
  }
}
